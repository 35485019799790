/*
 * @Author: ChenYaJin
 * @Date: 2023-07-11 16:53:37
 * @LastEditors: ChenYaJin
 * @LastEditTime: 2023-07-20 18:01:15
 * @Description:
 */
import { useOptionStore } from '~/stores/modules/option'

export interface IBehaviorAction {
  id?: string
  sketch?: string // 行为名称
  auditStatus?: string
  recognitionOrgan?: string // 表彰单位
  recognition?: string // 表彰时间
  enterpriseId?: string
  score?: number | string
  provePhoto?: string // 证明材料照片
  reviewBy?: string
  reject?: string
  reviewAt?: string
  deadlineAt?: string
}

export class BehaviorAction implements IBehaviorAction {
  id?: string
  sketch?: string // 行为名称
  auditStatus?: string
  recognitionOrgan?: string // 表彰单位
  recognition?: string // 表彰时间
  enterpriseId?: string
  score?: number | string
  provePhoto?: string // 证明材料照片
  reviewBy?: string
  reject?: string
  reviewAt?: string
  deadlineAt?: string

  constructor(json: IBehaviorAction = {}) {
    this.id = json.id
    this.sketch = json.sketch
    this.auditStatus = json.auditStatus
    this.recognitionOrgan = json.recognitionOrgan
    this.recognition = json.recognition
    this.enterpriseId = json.enterpriseId
    this.score = json.score
    this.provePhoto = json.provePhoto
    this.reviewBy = json.reviewBy
    this.reject = json.reject
    this.reviewAt = json.reviewAt
    this.deadlineAt = json.deadlineAt
  }

  get auditStatusText() {
    const optionStore = useOptionStore()
    return optionStore.getStatusName(this.auditStatus || '')
  }
}
