<!--
 * @Author: ChenYaJin
 * @Date: 2023-07-25 10:07:14
 * @LastEditors: wjc
 * @LastEditTime: 2023-12-12 17:57:35
 * @Description: 业务弹窗 - 支持预览/编辑/删除
-->
<template>
  <MDialog
    width="1000px"
    :footer-show="false"
    :modal-data="modalSetting"
    @open="onOpen"
    @close="onClose"
  >
    <template #header>
      <MHeader :title="modalSetting.title" />
    </template>
    <AuditStateComponent
      v-if="isShowAuditStatus"
      :audit-status-info="auditStatusInfo"
      class="status-row"
    />
    <template v-if="modalSetting.type === 'add'">
      <TableSettingComponent
        ref="tableSettingRef"
        :is-view="modalSetting.isShow"
        :is-form="modalSetting.isShow"
        :table-setting="tableSetting"
        :form-data="formData"
        :form-options="formOptions"
      />
    </template>
    <template v-else>
      <TableSettingComponent
        ref="tableSettingRef"
        :is-view="isView"
        :is-form="isEdit"
        :table-setting="tableSetting"
        :form-data="formData"
        :form-options="formOptions"
      />
    </template>
    <div v-if="type === BusinessType.community && isView" class="score-list-wrapper">
      <ActivityScoreList :score-list="scoreList" />
    </div>

    <template #footer>
      <div class="footer">
        <el-button v-if="isDelete" link type="danger" class="delete-button" @click="onDelete">
          删除
        </el-button>
        <template v-if="isClose">
          <el-button @click="onClose">关闭</el-button>
        </template>
        <template v-else>
          <template v-if="isView && !isEdit">
            <el-button type="danger" plain @click="onClose">关闭</el-button>
            <el-button type="primary" @click="onEdit">编辑</el-button>
          </template>
          <template v-else>
            <el-button type="danger" @click="onClose">取消</el-button>
            <el-button type="primary" @click="onSubmit">提交</el-button>
          </template>
        </template>
      </div>
    </template>
  </MDialog>
</template>

<script setup lang="ts">
  import TableSettingComponent from '../components/table-setting/index.vue'
  import AuditStateComponent from '../components/audit-status/index.vue'
  import ActivityScoreList from './activity-score-list.vue'
  import type { ModalData, IAuditStatusInfo, BasicResponse, IOption } from '~/models/common'
  import { BusinessType, BusinessTypeTitle, AuditStatusEnum, EmployeeType } from '~/models/enums'
  import { type IBehaviorAction, BehaviorAction } from '~/models/behavior'
  import {
    projectTableSetting,
    behaviorTableSetting,
    employeeTableSetting,
  } from '../common/table_setting'
  import {
    postProjectDataNormalize,
    postEmployeeDataNormalize,
    postIBehaviorDataNormalize,
  } from '../common/serialize'
  import {
    deleteEmployee,
    getEmployeeDetail,
    postEmployee,
    putEmployee,
  } from '~/api/enterprise/employee'
  import {
    deleteProject,
    getProjectDetail,
    postProject,
    putProject,
  } from '~/api/enterprise/project'
  import {
    getBehaviorDetail,
    postBehavior,
    putBehavior,
    deleteBehavior,
  } from '~/api/enterprise/behavior'
  import { Project, type IProject } from '~/models/project'
  import { Employee, type IEmployee } from '~/models/employee'
  import type { ITableItemSetting } from '~/models/table-setting'
  import { useOptionStore } from '~/stores/modules/option'
  import { getActivityScoreList } from '~/api/activity/info'
  import type { IActivityScore } from '~/models/activity'
  type BusinessTypeUnit =
    | IBehaviorAction
    | BehaviorAction
    | IProject
    | Project
    | IEmployee
    | Employee
  defineOptions({
    name: 'BusinessTypeModal',
  })
  interface selectEnums {
    type: IOption[]
    residence: IOption[]
    other: IOption[]
    way: IOption[]
    outsource: IOption[]
  }
  interface Props {
    modalData: ModalData<BusinessTypeUnit>
    enterpriseId: string
    type: string
    enterpriseRole?: EmployeeType
  }
  const props = withDefaults(defineProps<Props>(), {
    modalData: () => {
      return {
        isShow: false,
        title: '',
      }
    },
    enterpriseId: '',
    type: BusinessType.behavior,
    enterpriseRole: EmployeeType.MANAGER,
  })
  const emits = defineEmits(['close', 'refresh'])
  const optionStore = useOptionStore()
  const tableSetting = ref<ITableItemSetting[][]>([])
  const formData = ref<BehaviorAction>(new BehaviorAction())
  const modalSetting = ref<ModalData<BusinessTypeUnit>>({
    isShow: false,
    title: '',
  })

  const isClose = ref(false)
  const isDelete = ref(false)
  const isView = ref(false)
  const isEdit = ref(false)
  const isShowAuditStatus = ref(false)
  const auditStatusInfo = ref<IAuditStatusInfo>({})
  const tableSettingRef = ref()
  const scoreList = ref<IActivityScore[]>([])
  watch(
    () => props.modalData,
    (newValue: ModalData<BusinessTypeUnit>) => {
      modalSetting.value = newValue
    },
    {
      immediate: true,
    }
  )

  const formOptions = ref<selectEnums>({
    type: [],
    residence: [],
    other: [],
    way: [],
    outsource: [],
  })
  /** 获取相关枚举 */
  const getEnums = () => {
    if (props.type === BusinessType.community) {
      const promises = [
        optionStore.getProjectTypeInfo(),
        optionStore.getProjectTypeHouseInfo(),
        optionStore.getProjectTypeOtherInfo(),
        optionStore.getProjectWayFirstInfo(),
        optionStore.getProjectWaySecondInfo(),
      ]
      Promise.all(promises).then((res) => {
        formOptions.value.type = res[0]
        formOptions.value.residence = res[1]
        formOptions.value.other = res[2]
        formOptions.value.way = res[3]
        formOptions.value.outsource = res[4]
      })
    }
  }
  /** 数据初始化 */
  const onOpen = () => {
    setTableSetting()
    if (props.modalData.type === 'add') {
      resetStatus()
      resetFormData()
    } else {
      getDetail()
    }
    getScoreList()
    getEnums()
  }
  /**
   * 获取活动评分列表，目前只支持项目类型
   */
  const getScoreList = () => {
    if (props.type !== BusinessType.community) {
      return
    }
    const id = modalSetting.value.row?.id || ''
    const params = {
      enterpriseId: props.enterpriseId,
      businessId: id,
    }
    getActivityScoreList(params).then((res) => {
      scoreList.value = res.data.list || []
    })
  }
  /** 配置表格 */
  const setTableSetting = () => {
    switch (props.type) {
      case BusinessType.behavior:
        tableSetting.value = behaviorTableSetting.setting
        break
      case BusinessType.community:
        tableSetting.value = projectTableSetting.setting
        break
      case BusinessType.manager:
        tableSetting.value = employeeTableSetting.setting
        break
      case BusinessType.technician:
        tableSetting.value = employeeTableSetting.setting
        break
    }
  }
  /** 表单初始化 */
  const resetFormData = () => {
    switch (props.type) {
      case BusinessType.behavior:
        formData.value = new BehaviorAction()
        break
      case BusinessType.community:
        formData.value = new Project()
        break
      case BusinessType.manager:
        formData.value = new Employee()
        break
      case BusinessType.technician:
        formData.value = new Employee()
        break
    }
  }
  /** 获取详情 */
  const getDetail = () => {
    const id = modalSetting.value.row?.id || ''
    switch (props.type) {
      case BusinessType.behavior:
        getBehaviorInfo(id)
        break
      case BusinessType.community:
        getProjectInfo(id)
        break
      case BusinessType.manager:
        getEmployeeInfo(id)
        break
      case BusinessType.technician:
        getEmployeeInfo(id)
        break
    }
  }
  const getBehaviorInfo = (id: string) => {
    modalSetting.value.loading = true
    getBehaviorDetail(id)
      .then((res) => {
        formData.value = new BehaviorAction(res.data || {})
        setStatus(formData.value)
      })
      .finally(() => {
        modalSetting.value.loading = false
      })
  }
  const getEmployeeInfo = (id: string) => {
    modalSetting.value.loading = true
    getEmployeeDetail(id)
      .then((res) => {
        formData.value = new Employee(res.data || {})
        setStatus(formData.value)
      })
      .finally(() => {
        modalSetting.value.loading = false
      })
  }
  const getProjectInfo = (id: string) => {
    modalSetting.value.loading = true
    getProjectDetail(id)
      .then((res) => {
        formData.value = new Project(res.data || {})
        setStatus(formData.value)
      })
      .finally(() => {
        modalSetting.value.loading = false
      })
  }
  const setStatus = (data: BusinessTypeUnit) => {
    isClose.value = data?.auditStatus === AuditStatusEnum.REVIEW
    isDelete.value = data?.auditStatus !== AuditStatusEnum.REVIEW
    isView.value = true
    isShowAuditStatus.value = true
    const { auditStatus, reviewBy, score, reject, reviewAt, deadlineAt } = data
    auditStatusInfo.value = {
      auditStatus,
      reviewBy,
      score: Number(score),
      reject,
      content: BusinessTypeTitle[props.type as BusinessType],
      reviewAt,
      deadlineAt,
    }
  }
  const resetStatus = () => {
    isClose.value = false
    isDelete.value = false
    isView.value = false
    isShowAuditStatus.value = false
  }
  const onClose = () => {
    tableSettingRef?.value.customFormRef?.resetFields()
    modalSetting.value.isShow = false
    formData.value = new Project({})
    isEdit.value = false
    emits('close')
  }

  const onEdit = () => {
    isView.value = true
    isEdit.value = true
  }
  /** 删除提示 */
  const onDelete = () => {
    ElMessageBox.confirm('<strong>删除后无法恢复，是否确认删除！</strong>', '', {
      dangerouslyUseHTMLString: true,
      confirmButtonText: '取消删除',
      cancelButtonText: '确认删除',
      closeOnClickModal: false,
      showClose: false,
      confirmButtonClass: 'el-button el-button--primary',
      cancelButtonClass: 'el-button el-button--danger is-plain',
    })
      .then((e) => e)
      .catch(() => {
        deleteBusinessType(formData.value?.id || '').then((res) => {
          if (res.data) {
            ElMessage.success('删除成功！')
            emits('close')
            emits('refresh')
          }
        })
      })
  }
  const deleteBusinessType = (id: string): Promise<BasicResponse<number>> => {
    switch (props.type as BusinessType) {
      case BusinessType.behavior:
        return deleteBehavior(id)
      case BusinessType.community:
        return deleteProject(id)
      case BusinessType.manager:
        return deleteEmployee(id)
      case BusinessType.technician:
        return deleteEmployee(id)
    }
  }
  /** 数据反序列化 */
  const serializeData = (data: BusinessTypeUnit) => {
    switch (props.type as BusinessType) {
      case BusinessType.behavior:
        return postIBehaviorDataNormalize(data as BehaviorAction)
      case BusinessType.community:
        return postProjectDataNormalize(data as Project)
      case BusinessType.manager:
        return modalSetting.value.type === 'add'
          ? {
              ...postEmployeeDataNormalize(data as Employee),
              ...{
                enterpriseRole: props.enterpriseRole,
              },
            }
          : {
              ...postEmployeeDataNormalize(data as Employee),
            }
      case BusinessType.technician:
        return modalSetting.value.type === 'add'
          ? {
              ...postEmployeeDataNormalize(data as Employee),
              ...{
                enterpriseRole: props.enterpriseRole,
              },
            }
          : {
              ...postEmployeeDataNormalize(data as Employee),
            }
    }
  }
  /** 数据提交 */
  const onSubmit = () => {
    tableSettingRef.value.customFormRef.validate((valid: boolean) => {
      if (valid) {
        const formData = tableSettingRef.value.tableData
        const data = {
          ...serializeData(formData),
          ...{
            enterpriseId: props.enterpriseId,
          },
        }
        saveBusinessType(data).then((res) => {
          if (res.data) {
            ElMessage.success('提交成功！')
            emits('close')
            emits('refresh')
          }
        })
      }
    })
  }
  /** 添加/更新 封装 */
  const saveBusinessType = (data: BusinessTypeUnit) => {
    switch (props.type as BusinessType) {
      case BusinessType.behavior:
        return modalSetting.value.type === 'add'
          ? postBehavior(data)
          : putBehavior(props.modalData.row?.id || '', data)
      case BusinessType.community:
        return modalSetting.value.type === 'add'
          ? postProject(data as IProject)
          : putProject(props.modalData.row?.id || '', data as IProject)
      case BusinessType.manager:
        return modalSetting.value.type === 'add'
          ? postEmployee(data)
          : putEmployee(props.modalData.row?.id || '', data)
      case BusinessType.technician:
        return modalSetting.value.type === 'add'
          ? postEmployee(data)
          : putEmployee(props.modalData.row?.id || '', data)
    }
  }
</script>

<style scoped lang="scss">
  :deep(.el-dialog .el-dialog__body) {
    padding-top: 0 !important;
  }
  .status-row {
    @apply w-full mb-1rem;
  }
  .footer {
    text-align: center;
    padding-top: 20px;
  }
  .delete-button {
    @apply position-absolute left-2rem mt-0.5rem;
  }
  .score-list-wrapper {
    @apply max-h-200px overflow-scroll;
  }
</style>
